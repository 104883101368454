<template>
  <div>
    <header-slot v-if="moduleId != 5" />

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: pendingTabName }"
        exact
        :active="isPendingTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        PENDING
      </b-nav-item>

      <b-nav-item
        :to="{ name: completedTabName }"
        exact
        :active="isCompleteTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        COMPLETED
      </b-nav-item>
      <b-nav-item
        :to="{ name: deletedTabName }"
        exact
        :active="isDeletedTab"
        :link-classes="['px-3', bgTabsNavs]"
      >
        DELETED
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
  },
  computed: {

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    pendingTabName() {
      const { routeDirectPending } = this.moduleId != 5 ? this.$route.matched[1].meta : this.$route.matched[3].meta;
      return routeDirectPending;
    },
    completedTabName() {
      const { routeDirectCompleted } = this.moduleId != 5 ? this.$route.matched[1].meta : this.$route.matched[3].meta;
      return routeDirectCompleted;
    },
    deletedTabName() {
      const { routeDirectToDeleted } = this.moduleId != 5 ? this.$route.matched[1].meta : this.$route.matched[3].meta;
      return routeDirectToDeleted;
    },

    isPendingTab() {
      return this.pendingTabName == this.$route.name;
    },
    isCompleteTab() {
      return this.completedTabName == this.$route.name;
    },
    isDeletedTab() {
      return this.deletedTabName == this.$route.name;
    },
  },
  methods: {},
};
</script>
